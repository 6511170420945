<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Testimonials
                        <v-spacer></v-spacer>
                        <!--<v-btn outlined @click="form.dialog = true" ma-0 small>New Testimonial</v-btn>-->
                        <add-button @action="form.dialog = true, $refs.form ? $refs.form.reset(): ''" permission="testimonial-create">
                            New Testimonial
                        </add-button>
                    </v-card-title>
                    <v-data-table
                            :headers="headers"
                            :items="form.items.data"
                            :options.sync="pagination"
                            :footer-props="footerProps"
                            :server-items-length="form.items.meta.total">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td class="text-center">{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">
                                    <v-list-item ma-0 pa-0>
                                        <v-list-item-avatar>
                                            <img :src="item.image">
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <small><strong>{{item.title}}</strong></small>
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="grey--text text--lighten-1">
                                                <small>{{ item.name }}</small>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </td>
                                <td class="text-xs-left">{{ item.description.cleanHtml(100) }}</td>
                                <td class="text-center">{{ item.rank || 'N/A' }}</td>
                                <td class="text-right">
                                    <edit-button permission="testimonial-update" @agree="form.edit(item)"/>
                                    <delete-button permission="testimonial-delete" @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" scrollable persistent max-width="800px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Testimonial Details</span>
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <v-form ref="form" @submit.prevent="store"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="store"
                                v-model="valid"
                                :lazy-validation="lazy">
                            <v-container grid-list-md>
                                <v-layout row wrap>
                                    <v-flex xs6>
                                        <v-text-field 
                                            label="Designation*" placeholder="Head Master" autocomplete="off"
                                            required class="pa-0" v-model="form.name" name="name"
                                            :error-messages="form.errors.get('name')"
                                            outlined dense
                                            hint="Designation of testimonial creator."
                                            :rules="[v => !!v || 'Designation Field is required']"/>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select outlined dense :items="ranks" label="Ranking/Ordering" v-model="form.rank"
                                                  class="pt-0"
                                                  :rules="[v => !!v || 'Ranking Field is required']"
                                        ></v-select>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-text-field outlined dense label="Testimonial Name*" autocomplete="off" required class="pa-0"
                                                      v-model="form.title" name="name"
                                                      :error-messages="form.errors.get('name')"
                                                      :rules="[v => !!v || 'Testimonial Field is required']"/>
                                    </v-flex>
                                    <v-flex xs12 class="ma-0 pa-0">
                                        <wysiwyg v-model="form.description" />

                                        <!--<v-textarea outlined solo :error-messages="form.errors.get('description')"-->
                                        <!--v-model="form.description" name="description" label="Description*"-->
                                        <!--hint="Short and sweet notice is always eye catching."></v-textarea>-->
                                    </v-flex>
                                    <v-scroll-y-reverse-transition>
                                        <v-flex xs12 text-center class="mt-3 pa-0" v-if="imagePicker.image"
                                                style="position: relative;">
                                            <v-avatar :size="112" color="grey lighten-4">
                                                <img :src="imagePicker.image" alt="avatar">
                                            </v-avatar>
                                        </v-flex>
                                        <v-flex xs12 text-center class="mt-3 pa-0" v-else-if="form.data().image"
                                                style="position: relative;">
                                            <v-avatar :size="112" color="grey lighten-4">
                                                <img :src="form.data().image" alt="avatar">
                                            </v-avatar>
                                        </v-flex>
                                    </v-scroll-y-reverse-transition>
                                    <v-flex xs12 text-center class="">
                                        <!-- <image-picker ref="picker" :ratio="16/9" @result="onPickerResult"></image-picker> -->
                                        <image-picker label="Select Testimonial Image" ref="picker" :ratio="1" @result="onPickerResult"></image-picker>
                                    </v-flex>
                                    <v-flex xs12>
                                        <small>*indicates required field</small>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" text @click="validate">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            valid: true,
            lazy: false,
            form: new Form({
                title: '',
                name: '',
                description: '',
                image: '',
                rank: '',
            }, '/api/testimonial'),
            imagePicker: {
                name: null,
                image: null,
                src: null,
                cropped: {}
            },
            search: null,
            pagination: {},
            ranks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Title', align: 'left', value: 'title'},
                {text: 'Description', align: 'left', value: 'description', sortable: false},
                {text: 'Rank', align: 'center', value: 'rank', width: 80},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
        }),

        computed: {
            ...mapState(['batch']),


        },
        watch: {
            'pagination': function () {
                this.get();
            }
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
                    if (typeof json[key] === 'object') {
                        let vals = [...json[key]];
                        if (vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
                    }
                    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                }).join('&');
            },
            validate() {
                if (this.$refs.form.validate()) {
                    this.store()
                }
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },

            store() {
                if (this.imagePicker && Object.keys(this.imagePicker.cropped).length > 0) {
                    this.form.image = this.imagePicker.cropped;
                } else {
                    delete this.form.image;
                }
                this.form.store();
            },
            getWords(str) {
                return str.split(/\s+/).slice(0, 10).join(" ") + '...';
            },

            onPickerResult(data) {
                this.imagePicker = data;
                this.$refs.picker.reset();
            },

        }
    }
</script>
<style lang="scss" scoped></style>